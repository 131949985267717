import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import ax from 'utils/ax';

export interface ICategories {
  name: string;
  active: boolean;
}

const availabilityArray = [
  {name: 'Все', active: true},
  {name: 'В наличии', active: false}
];

export interface IAvailability {
  name: string;
  active: boolean;
}

export type INominalsToOrder = {
  id: number;
  type: string;
  nominal: string;
  qty: number;
  counter: number;
  time?: string;
}

export type INominal = {
  nominal: string;
  qty: number;
  count: number;
}

export interface ICardData {
  CertGUID: string;
  name: string;
  description: string;
  nominals: {[key: string]: INominal};
  user_markup?: string;
  markup?: string | { name: string; markup: number | string};
  step: number;
  category: string;
  issuerId: string;
  issuer: string;
  DeliveryTime: string;
  limitations: string;
  max_nominal: number;
  min_nominal: string;
  validity_period_nominal: string;
  reception_locations: string;
  format: string;
  validity_period: string;
  terms_of_use: string;
  file_link: string;
  nominalsToOrder?: INominalsToOrder[];
  in_cart: boolean;
  user_discount: number;
  id: string;
  guid_1c: string;
}

export interface ICard {
  card_data: ICardData;
  card_id: string;
  created_at: string;
  id: number;
  updated_at: string;
}

export interface IPrizes {
  cards: {[key: string]: ICardData };
  markups: {[key: string]: string};
  cardsFilter: ICardData[];
  cardsFilterLength: number;
  cardsWithoutAuth: ICardData[];
  cardsTotal: number;
  loading: {
    [key: string]: boolean,
  };
  categories: ICategories[];
  activeCategoriesList: {[key: string]: string};
  availability: IAvailability[];
  changeSearchData: boolean;
  categoriesFilterCards: ICardData[];
  searchFilterCards: ICardData[];
  card: ICardData;
  visibleForm: boolean;
  searchQuery?: string;
}

export const getCards = createAsyncThunk(
  'prizes/getCards',
  async (payload: {from: number, to: number, mode?: boolean, pos?: string, modeEps?: string }, {getState}) => {
    const {dashboard, prizes}: any = getState();
    const categories =
      Object.values(prizes.activeCategoriesList).length && !Object.values(prizes.activeCategoriesList).includes('Все')
        ? Object.values(prizes.activeCategoriesList)
        : undefined;
    const searchQuery = prizes.searchQuery.length ? prizes.searchQuery : undefined;
    if (dashboard.purse) {
      try {
        const {data} = await ax().post(
          'api/cards/get-cards',
          {
            purse_id: dashboard.purse && dashboard.purse.id,
            categories: !payload.mode ? categories : undefined,
            in_stock: !payload.mode && prizes.availability[1].active ? 'true' : undefined,
            from: payload.from,
            to: payload.to,
            pos: payload.pos,
            name: searchQuery,
            is_eps: payload.modeEps !== 'plastic',
          }
        );

        if (data.data.code === 'error') {
          return [];
        } else {
          if (payload.from > 0) {
            data.data.type = 'scroll';
          }
          return data.data;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
);

export const getMarkups = createAsyncThunk(
  'prizes/getMarkups',
  async (payload, {getState}) => {
    const {dashboard}: any = getState();

    if (dashboard.purse) {
      try {
        const {data} = await ax().post(
          'api/cards/get-markups',
          {purse_id: dashboard.purse && dashboard.purse.id}
        );

        if (data.data.code === 'error') {
          return {};
        } else {
          return data.data.result;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
);

export const getNominals = createAsyncThunk(
  'prizes/getNominals',
  async (payload: string, {getState}) => {
    const {dashboard}: any = getState();
    if (dashboard.purse) {
      try {
        const {data} = await ax().post(
          'api/cards/update-eps',
          {eps: [payload]}
        );

        if (data.data.code === 'error') {
          return {};
        } else {
          return data.data.result[0][payload];
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
);

export const getCategories = createAsyncThunk(
  'prizes/getCategories',
  async (payload: { mode: string }) => {
    const params: any = {};

    if (payload.mode === 'plastic') {
      params.is_plastic = 1;
    }

    try {
      const {data} = await ax().get(
        'api/cards/get-categories/project',
        {params}
      );

      const code = data.data.code;

      if (code === 'error') {
        return;
      } else {
        return data.data.result;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const getQtyCardInNominals = createAsyncThunk(
  'prizes/getQtyCardInNominals',
  async (payload: { id: string } | undefined, {getState}) => {
    const {prizes}: any = getState();

    if (prizes.card || payload?.id) {
      try {
        const {data} = await ax().post('api/cards/update-eps', {eps: [prizes.card.card_id || payload?.id]});

        if (data.data.code !== 'error') {
          return data.data.result[0];
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
);

const PrizesSlice = createSlice({
  name: 'prizes',
  initialState: {
    markups: {},
    cards: {},
    settings: {},
    cardsTotal: 0,
    card: {
      CertGUID: '',
      name: '',
      description: '',
      nominals: {},
      markup: '',
      step: 0,
      category: '',
      issuerId: '',
      issuer: '',
      DeliveryTime: '',
      limitations: '',
      max_nominal: 0,
      min_nominal: '',
      validity_period_nominal: '',
      reception_locations: '',
      format: '',
      validity_period: '',
      terms_of_use: '',
      file_link: '',
      nominalsToOrder: [],
      in_cart: false,
      user_discount: 0,
      id: '',
      guid_1c: '',
    },
    cardsWithoutAuth: [],
    cardsFilter: [],
    cardsFilterLength: 0,
    loading: {
      prizes: false,
      markups: false,
      categories: false,
      nominalTable: false,
      nominals: false,
      cartLoading: false,
      pursesLoading: false,
    },
    categoriesFilterCards: [],
    activeCategoriesList: {},
    categories: [],
    changeSearchData: false,
    availability: availabilityArray,
    searchFilterCards: [],
    visibleForm: false,
  },
  reducers: {
    // setCardsFilter: (state: IPrizes) => {
    //   if (!state.categoriesFilterCards?.length && !state.categories.find((item, index, array) => item.active && item.name !== array[0].name) && !state.searchFilterCards?.length && !state.searchQuery?.length) {
    //
    //     if (state.categories[0]) {
    //       state.categories[0].active = true;
    //     }
    //   } else {
    //     const categoriesFilterCards = state.categoriesFilterCards;
    //
    //     const searchFilterCards = state.searchFilterCards;
    //
    //     if (state.categoriesFilterCards.length && state.searchQuery?.length) {
    //       state.cardsFilter =
    //         categoriesFilterCards.filter((card: ICardData) =>
    //           searchFilterCards.find((card_id: ICardData) => card_id.id === card.id)
    //         );
    //     } else {
    //       state.cardsFilter = categoriesFilterCards.length ? categoriesFilterCards : searchFilterCards;
    //     }
    //   }
    //
    //   state.cardsFilterLength = state.cardsFilter?.find(card => card.category === 'Впечатления')
    //     ? state.cardsFilter?.filter(card => card.category !== 'Впечатления').length + 1
    //     : state.cardsFilter?.length;
    // },
    setInCart: (state: IPrizes, {payload}) => {
      const index = state.cardsFilter.findIndex(item => item.guid_1c === payload.guid_1c);
      if (index && state.cardsFilter[index]) {
        state.cardsFilter[index].in_cart = true;
      }
    },
    removeFilterCard:(state: IPrizes) => {
      state.searchFilterCards = [];
      state.categoriesFilterCards = [];
    },
    setCard: (state: IPrizes, {payload}: {payload: string}) => {
      const cardNominal = state.card.nominals;
      state.card = state.cards[payload];
      state.card.nominals = cardNominal;
      if (state.markups[payload]) {
        state.card.markup = state.markups[payload];
      }
    },
    setLoading: (state: IPrizes, {payload}) => {
      state.loading[payload.name] = payload.value;
    },
    setVisibleForm: (state: IPrizes, {payload}) => {
      state.visibleForm = payload;
    },
    setSearchFilter: (state: IPrizes, {payload}) => {
      state.searchQuery = payload;
    },
    setChangeSearchData: (state: IPrizes, {payload}: {payload: boolean}) => {
      state.changeSearchData = payload;
    },
    setActiveCategory: (state: IPrizes, {payload}: {payload: number}) => {
      state.categories[payload].active = !state.categories[payload].active;

      if (state.categories[payload].active) {
        state.activeCategoriesList[state.categories[payload].name] = state.categories[payload].name;
      } else {
        delete state.activeCategoriesList[state.categories[payload].name];
      }

      if (payload > 0) {
        delete state.activeCategoriesList[state.categories[0].name];
      } else {
        state.activeCategoriesList = {};
      }

      if (payload === 0) {
        state.categories[0].active = true;
        state.categories.map((category, index) =>
          index !== 0
            ? category.active = false
            : category.active = true
        );
        state.categoriesFilterCards = [];
      } else {
        state.categories[0].active = false;
      }
    },
    setActiveAvailability: (state: IPrizes, {payload}) => {
      state.availability = state.availability.map(item => {
        item.active = false;
        return item;
      });
      state.availability[payload].active = true;
    },
    clearOrderCard: (state: IPrizes) => {
      const nominals= Object.keys(state.card.nominals);

      nominals.map((item: string) => {
        state.card.nominals[item].count = 0;
        return;
      });
    },
    addMarkupsToCards: (state: IPrizes) => {
      if (!Object.entries(state.markups).length) {
        return;
      }
      Object.entries(state.markups).forEach((item)=> {
        if (state.cards[item[0]]) {
          const elem = state.cards[item[0]];
          elem.markup = item[1];
          state.cards[item[0]] = elem;
        }
      });
    },
    handleCounter: (state: IPrizes, {payload}: {payload: {id: string, mode: string}}) => {
      const changeMode = (nominal: INominal): number => {
        if (payload.mode === 'clear') {
          return nominal.count = 0;
        } else if (payload.mode === 'plus') {
          return +nominal.count + 1;
        } else if (payload.mode === 'minus') {
          if (+nominal.count > 0) {
            return +nominal.count - 1;
          }
        }
        return 0;
      };

      const nominalsToOrder = state.card.nominals;
      const findNominalToOrder: INominal = nominalsToOrder[payload.id];

      if (!(
        payload.mode === 'plus' &&
        findNominalToOrder.count + 1 > +findNominalToOrder.qty &&
        +findNominalToOrder.qty !== 0
      )) {
        findNominalToOrder.count = changeMode(nominalsToOrder[payload.id]);
      }
    },
    addNominalsInCard: (state: IPrizes, {payload}) => {
      if (!state.card.nominals) {
        state.card.nominals = {};
      }

      const randomId = Math.floor(Math.random() * 10000000);
      const nominalsToOrder = state.card.nominals;

      const findIsNominal =
        nominalsToOrder[`${payload.nominal}`];

      if (findIsNominal && findIsNominal.count + +payload.qty <= findIsNominal.qty) {
        state.card.nominals[`${payload.nominal}`].count += 1;
      } else {
        state.card.nominals = {
          ...nominalsToOrder,
          [`${payload.nominal}`]: {
            qty: 0,
            count: payload.qty,
            nominal: payload.nominal
          }
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCards.fulfilled, (state: IPrizes, {payload}) => {
      if (payload?.result && payload.result.card_data) {
        if (payload.result.total) {
          state.cardsTotal = payload.result.total;
        }
        const normalizedData: {[key: string]: ICardData} = payload.result.card_data.reduce((acc: {[key: string]: ICardData}, item: ICardData) => {
          acc[item.guid_1c] = item;
          return acc;
        }, {});
        if (payload.type === 'scroll') {
          state.cards = {...state.cards, ...normalizedData};
        } else {
          state.cards = normalizedData;
        }
      } else {
        state.cards = {};
      }
      state.loading['prizes'] = false;
    });
    builder.addCase(getCards.pending, (state: IPrizes) => {
      state.loading['prizes'] = true;
    });
    builder.addCase(getMarkups.fulfilled, (state: IPrizes, {payload}) => {
      state.markups = payload;
      state.loading['markups'] = false;
    });
    builder.addCase(getMarkups.pending, (state: IPrizes) => {
      state.loading['markups'] = true;
    });
    builder.addCase(getNominals.fulfilled, (state: IPrizes, {payload}: {payload: {[key: string]: number}}) => {
      const normalizedNominals = Object.entries(payload).reduce((acc, item) => {
        acc[item[0]] = {
          qty: item[1],
          count: 0,
          nominal: item[0]
        };
        return acc;
      }, {} as {[key: string]: INominal});
      state.card.nominals = normalizedNominals;
      state.loading['nominals'] = false;
    });
    builder.addCase(getNominals.pending, (state: IPrizes) => {
      state.loading['nominals'] = true;
    });
    builder.addCase(getQtyCardInNominals.fulfilled, (state: IPrizes, {payload}) => {
      if (!payload) {
        return;
      }
      const result: any = Object.values(payload);

      const card_data = state.card;
      const listPurchasedNominals = result[0] && Object.entries(result[0]).map(item => item[0]);
      const difference = Object.values(card_data.nominals).filter((x: any) => !listPurchasedNominals?.includes(x.Nominal));

      card_data.nominalsToOrder = [];

      if (result[0]) {
        for (const item of Object.entries(result[0])) {
          const nominal = item[0];
          const qty = item[1];
          const randomId = Math.floor(Math.random() * 1000000);

          card_data.nominalsToOrder.push({
            id: randomId,
            type: 'is',
            nominal,
            qty: 18,
            counter: 0,
          });
        }
      }

      if (+card_data.step === 0) {
        for (const nominal of difference) {
          const randomId = Math.floor(Math.random() * 1000000);

          card_data.nominalsToOrder.push({
            id: randomId,
            type: 'no',
            time: 'nominal.ValidityPeriod',
            nominal: '501',
            qty: 0,
            counter: 0,
          });
        }
      }
    });
    builder.addCase(getCategories.fulfilled, (state: IPrizes, {payload}: {payload: {id: string, name: string}[]}) => {
      if (payload && payload.length > 0) {
        state.categories = payload.map((category: {id: string, name: string}) => {
          const active = state.categories[state.categories.findIndex((item) => item.name === category.name)]?.active;
          return {name: category.name, active: active};
        });
        state.categories.unshift({name: 'Все', active: state.categories[0]?.name === 'Все' && !!state.categories[0]?.active});
      }
    });
  },
});

export const {
  setInCart,
  setLoading,
  // setCardsFilter,
  setActiveCategory,
  setActiveAvailability,
  setCard,
  removeFilterCard,
  setSearchFilter,
  clearOrderCard,
  handleCounter,
  addNominalsInCard,
  setVisibleForm,
  addMarkupsToCards,
  setChangeSearchData,
} = PrizesSlice.actions;

export default PrizesSlice.reducer;

