import axios from 'axios';

import {baseURL} from 'env/env.json';
import {StorageItemsNames} from 'enums';
import {store} from '../store/store';
import {logOutAndResetStore, resetPurses} from '../store/components/AuthSlice';
import {showPopup} from '../store/components/PopupSlice';
import { clearPurse, resetMoneyTransfer } from 'store/components/DashboardSlice';

let i = 0;

export const logOutError = async () => {
  const data: {[key: string]: string} = {};

  const state: any = store.getState();

  const refresh = await localStorage.getItem(StorageItemsNames.REFRESH_TOKEN);

  try {
    const response = await axios.post(baseURL + 'api/auth/refresh', {refresh_token: refresh});

    await localStorage.setItem(StorageItemsNames.REFRESH_TOKEN, response.data.data.token.refresh_token);
    await localStorage.setItem(StorageItemsNames.TOKEN, response.data.data.token.access_token);
    await window.location.replace('/#/');
    return;
  } catch (e) {
    if (!state.settings.allowAuth) {
      store.dispatch(showPopup('dontAllowAuth'));
    }

    if (state.auth.user) {
      const user = state.auth.user;
      const phone = user.phone;
      
      if (phone) {
        data['phone'] = phone;
      }
      data['card'] = localStorage.getItem(StorageItemsNames.CARD_NUMBER) || '';
      store.dispatch(resetPurses([]));
      store.dispatch(clearPurse());
      store.dispatch(resetMoneyTransfer());
    }

    const keysValues = Object.entries(data);

    const first = `${keysValues[0][0]}=${keysValues[0][1]}`;
    const second = keysValues[1] ? `${keysValues[1][0]}=${keysValues[1][1]}` : '';
  
    if (keysValues.length) {
      state.auth.routerHistory(`/dashboard/prizes?${first}&${second}`);
    }

    const onCloseAuthPopup = () => {
      store.dispatch(logOutAndResetStore());

      if (window.location.href.includes('dashboard')) {
        state.auth.routerHistory(`/?${first}&${second}`);
      }
    };

    window.onbeforeunload = () => {
      state.auth.routerHistory('/');
    };

    store.dispatch(showPopup({popup: 'auth', props: {onClose: onCloseAuthPopup}}));
    // @ts-ignore
  } finally
  {
    i = 0;
  }
};

const ax = (): any => {
  const token = localStorage.getItem(StorageItemsNames.TOKEN);

  const ax = axios.create({
    baseURL,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  ax.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const state = store.getState();

    if (error.response?.status === 401 && state.auth.user !== undefined && i === 0) {
      logOutError();
      i = 1;
    }

    return Promise.reject(error);
  });
  return ax;
};

export default ax;
