import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';

import env from 'env/env.json';

import {StorageItemsNames} from 'enums';

import {getSearchForOtherProject} from 'utils/hooks';

import {IStore} from 'store/store';
import {setRouterHistory} from '../store/components/AuthSlice';

import GiftPage from 'pages/giftPage/GiftPage';
import Dashboard from 'pages/dashboard/Dashboard';

const MainPage = React.lazy(() => import('../pages/mainPage/MainPage'));
const MainPageNewYear = React.lazy(() => import('../pages/mainPageNewYear/MainPageNewYear'));
const WomenDay = React.lazy(() => import('../pages/womenDay/WomenDay'));
const MenDay = React.lazy(() => import('../pages/menDay/MenDay'));
const Megafon = React.lazy(() => import('../pages/megafon/Megafon'));
const MTSPage = React.lazy(() => import('../pages/MTSPage/MTSPage'));
const MTSMissionPage = React.lazy(() => import('../pages/MTSMissionPage/MTSMissionPage'));
const Brucite = React.lazy(() => import('../pages/Brucite/Brucite'));
const MakfaPage = React.lazy(() => import('../pages/makfaPage/MakfaPage'));
const NLoto = React.lazy(() => import('../pages/NLoto/NLoto'));
const NLotoPR = React.lazy(() => import('../pages/NLotoPR/NLotoPR'));
const Smeg = React.lazy(() => import('../pages/smegPage/Smeg'));
const Birthday2023 = React.lazy(() => import('../pages/Birthday2023/Birthday2023'));
const GazpromPage = React.lazy(() => import('../pages/gazpromPage/GazpromPage'));
const AClub = React.lazy(() => import('../pages/AClub/AClub'));
const Raiffeisen = React.lazy(() => import('../pages/raiffeisenPage/Raiffeisen'));
const RuExp = React.lazy(() => import('../pages/rusEx/RuExp'));
const Leader_ABC = React.lazy(() => import('../pages/leader/Leader_ABC'));
const SaratovPage = React.lazy(() => import('../pages/saratovPage/SaratovPage'));
const Gazprom = React.lazy(() => import('../pages/GazpromNew/Gazprom'));
const MascoglassPage = React.lazy(() => import('../pages/mascoglass/MascoglassPage'));
const ASGPage = React.lazy(() => import('../pages/ASGPage/ASGPage'));
const GazpromNewYear = React.lazy(() => import('../pages/GazpromNewYear/GazpromNewYear'));
const Rubin = React.lazy(() => import('../pages/rubin/RubinPage'));
const Gdnprof = React.lazy(() => import('../pages/gdnprof/Gdnprof'));
const Rtmis = React.lazy(() => import('../pages/rtmis/Rtmis'));
const Stg = React.lazy(() => import('../pages/stg/Stg'));
const Pharmacy366 = React.lazy(() => import('../pages/pharmacy366/Pharmacy366'));

const MainNavigation: React.FC = () => {
  const {search, pathname} = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const authorized = useSelector((state: IStore) => state.auth.authorized);

  const date = new Date();

  const December = 11;
  const January = 0;

  const newYearDate = () => {
    return (date.getMonth() === December && date.getDate() >= 1) || (date.getMonth() === January && date.getDate() <= 15);
  };

  const getUser = localStorage.getItem(StorageItemsNames.USER);
  const token = localStorage.getItem(StorageItemsNames.TOKEN);

  const findWomenDay = () => {
    const href = window.location.href;

    if (href.includes('womens-day')) {
      return <WomenDay />;
    } else if (href.includes('men-day')) {
      return <MenDay />;
    } else if (href.includes('megafon')) {
      return <Megafon />;
    } else if (href.includes('mtsfirst')) {
      return <MTSPage />;
    } else if (href.includes('mts')) {
      return <MTSMissionPage />;
    } else if (href.includes('nloto')) {
      if (href.includes('pr')) {
        return <NLotoPR />;
      } else {
        return <NLoto />;
      }
    } else if (href.includes('makfa')) {
      return <MakfaPage />;
    } else if (href.includes('smeg')) {
      return <Smeg />;
    } else if (href.includes('birthday')) {
      return <Birthday2023 />;
    } else if (href.includes('raiff')) {
      return <Raiffeisen />;
    } else if (href.includes('brucite')) {
      return <Brucite />;
    } else if (href.includes('asgbonus')) {
      return <ASGPage />;
    } else if (href.includes('proektirovanie')) {
      return <Gazprom />;
    } else if (href.includes('mascoglass')) {
      return <MascoglassPage />;
    } else if (href.includes('rubin')) {
      return <Rubin />;
    } else if (href.includes('local')) {
      return <MainPage />;
    } else if (href.includes('366')) {
      return <Pharmacy366 />;
    } else if (href.includes('stg')) {
      return <Stg />;
    } else if (href.includes('rtmis')) {
      return <Rtmis />;
    } else if (href.includes('gdnprof')) {
      return <Gdnprof />;
    } else if (href.includes('geo')) {
      return <GazpromNewYear />;
    } else if (href.includes('pepsico')) {
      return <MainPage />;
    } else if (href.includes('sargazarm')) {
      return <SaratovPage />;
    } else if (href.includes('aclub')) {
      return <AClub />;
    } else if (href.includes('r-express')) {
      return <RuExp />;
    } else if (href.includes('lider_abc')) {
      return <Leader_ABC />;
    } else if (newYearDate()) {
      return <MainPageNewYear />;
    }

    return <MainPage />;
  };

  if (search.split('=')[1] === 'open') {
    setTimeout(() => {
      // @ts-ignore
      jivo_api.open();
    }, 1000);
  }

  useEffect(() => {
    if (getUser && pathname === '/' && authorized && token) {
      if (getSearchForOtherProject(search)) {
        document.location.href = `${env.urlSite}dashboard/prizes`;
      } else {
        history.push('/dashboard/prizes');
      }
    } else if (!getUser) {
      if (getSearchForOtherProject(search)) {
        history.push('/?auth=open-popup');
      }
    }
  }, [getUser]);

  useEffect(() => {
    dispatch(setRouterHistory(history.push));
  }, []);

  return (
    <Switch>
      <React.Suspense fallback={<></>}>
        <Route exact path={'/'} render={findWomenDay} />
        <Route exact path={'/gift/:guid'} render={() => <GiftPage />} />
        {
          authorized ? (
            <>
              <Route exact path={'/dashboard/prizes'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/plastic-prizes'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/money-transfer'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/history'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/activation-history'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/help'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/profile'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/ndfl'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/activation-eko'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/cart'} render={() => <Dashboard />} />
              <Route exact path={'/dashboard/place-order'} render={() => <Dashboard />} />
            </>
          ) : null
        }
      </React.Suspense>

    </Switch>
  );
};

export default MainNavigation;
