import {createSlice} from '@reduxjs/toolkit';

export interface IPopup {
  popup: {
    [key: string]: boolean,
  };
  props: any;
}

const PopupSlice = createSlice({
  name: 'popup',
  initialState: {
    popup: {
      testograf: false,
      auth: false,
      ndfl_info_popup: false,
      register: false,
      feedback: false,
      completedFeedback: false,
      orderCard: false,
      moneyTransferAlert: false,
      dontAllowAuth: false,
      tooManyAttempts: false,
      emailVerified: false,
      completedOrder: false,
      moneyTransferSuccess: false,
      moneyTransferReject: false,
      completedFastOrder: false,
      rejectOrder: false,
      deficiencyBalance: false,
      timerLeftOut: false,
      activationCard: false,
      EKOPopup: false,
      goToAccount: false,
      rejectActivatedCard: false,
      completedActivationCard: false,
      completedVerifiedEmail: false,
      acceptByWithAllOrder: false,
      insufficientData: false,
      longNoActive: false,
      orderStatusPopup: false,
      cardsOver: false,
      scaleProjectImage: false,
      checkCard: false,
      termsOfUse: false,
      noWallet: false,
      moneyTransfer: false,
      rejectTransfer: false,
      completedTransfer: false,
      changeUserData: false,
      checkNumberCard: false,
      contactManager: false,
      errorPlaceOrder: false,
      commissionTip: false,
    },
    props: '',
  },
  reducers: {
    showPopup: (state: IPopup, {payload}) => {
      state.popup[payload.popup ? payload.popup : payload] = true;

      if (payload.props) {
        state.props = payload.props;
      }
    },
    hidePopup: (state: IPopup, {payload}) => {
      state.popup[payload.popup ? payload.popup : payload] = false;

      if (payload.props) {
        state.props = payload.props;
      } else {
        state.props = {};
      }
    },
    hideAllExcept: (state: IPopup, {payload}: {payload: any}) => {
      const keyList = Object.keys(state.popup);
      if (keyList.length > 0) {
        keyList.forEach((item) => {
          state.popup[item] = payload === item;
        });
      }
    }
  }
});

export const { showPopup, hidePopup, hideAllExcept } = PopupSlice.actions;

export default PopupSlice.reducer;

