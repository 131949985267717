import React from 'react';

import {useAppSelector} from 'utils/hooks';

import Popup from 'components/popup/Popup';
import CommissionItem from './assets/components/CommissionItem';

import './CommissionTip.scss';

const CommissionTip = () => {
  const purse = useAppSelector((state) => state.dashboard.purse);
  const cards = useAppSelector(state => state.prizes.cards);
  const markups = useAppSelector((state) => state.prizes.markups);

  const commissionList = markups ? Object.entries(markups).slice(0, 3) : [];

  if (!purse) {
    return null;
  }

  return (
    <Popup 
      id={'commissionTip'} 
      className={'commission-tip'} 
      data-has-tax={purse.has_tax}
      data-commission-list={commissionList.length > 0}>
      <div className={'commission-tip__container'}>
        <div className={'commission-tip__header'}>
          <h2 className={'commission-tip__title'}>
            {(purse.has_tax && commissionList.length === 0) || (!purse.has_tax && commissionList.length === 0) 
              ? (
                <>
                  <span>Комиссия при обмене на сертификаты </span>
                  <span>не&nbsp;взымается</span>
                </>
              ) : 'Взымается комиссия'
            }
          </h2>
          {purse.has_tax ? (
            <p className={'commission-tip__ndfl'}>
              {purse.tax_payer === 'participant'
                ? 'НДФЛ оплачивается участником мотивационной программы +13%'
                : 'НДФЛ оплачивается организатором мотивационной программы'
              }.
            </p>
          ) : null}
        </div>
        {
          purse.bank_commission || commissionList.length ? (
            <div className={'commission-tip__info-container'}>
              {commissionList.map((item, index) => (
                <CommissionItem item={item} cards={cards} index={index} key={index} />
              ))}
              {purse.bank_commission ? (
                <div className={'commission-tip__item'}>
                  <span className={'commission-tip__name'}>Вывод баллов на банковскую карту</span>
                  <span className={'commission-tip__markup'}>+ {parseInt(purse.bank_commission || '')}%</span>
                </div>
              ) : null}
            </div>
          ) : null
        }
      </div>
    </Popup>
  );
};

export default CommissionTip;
