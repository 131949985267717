import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'utils/hooks';

import Title from 'components/title/Title';
import InnerTabs from 'components/innerTabs/InnerTabs';
import ActivationHistory from '../activationHistory/ActivationHistory';
import ProfileInfo from './assets/components/profileInfo/ProfileInfo';
import NDFLPage from '../NDFLPage/NDFLPage';
import NDFLWidget from '../NDFLWidget/NDFLWidget';

const Profile = () => {
  const location = useLocation();
  const history = useHistory();

  const [active, setActive] = useState(0);
  const [showWidget, setShowWidget] = useState<boolean>(false);
  const user = useAppSelector(state => state.auth.user);

  const hashes: any = {
    '#history': 1,
    '#ndfl': 2,
  };

  const acceptStatus = 2;
  const waitStatus = 1;
  const rejectStatus = 3;
  const tabBar = [
    {name: 'Личные данные', child: <ProfileInfo />},
    {name: 'История активаций', child: <ActivationHistory />},
    {
      name: 'Анкета НДФЛ',
      child: (
        user?.tax && (user?.tax.status === waitStatus
          || user?.tax.status === acceptStatus
          || user?.tax.status === rejectStatus))
      && !showWidget
        ? <NDFLPage
          onClick={() => {setShowWidget(true);}}
        />
        : <NDFLWidget />,
      hidden: !user?.tax
    },
  ];

  const changeActiveTab = (index: number) => {
    setActive(index);
    
    const hash = Object.keys(hashes).find(key => hashes[key] === index);
    
    if (hash) {
      history.push(`profile${hash}`);
    } else {
      history.push('profile');
    }
  };

  useEffect(() => {
    if (user?.tax  && (hashes[location.hash] === 0 || hashes[location.hash] > 0)) {
      setActive(hashes[location.hash]);
    } else { 
      setActive(0);
    }
  }, [location]);

  return (
    <div>
      <Title>Личный кабинет</Title>
      <InnerTabs changeActiveTab={changeActiveTab} tabs={tabBar} activeTab={active}/>
    </div>
  );
};

export default Profile;
