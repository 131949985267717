import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useAppDispatch} from 'utils/hooks';

import {StorageItemsNames} from 'enums';
import {IStore} from 'store/store';
import {activeCard} from 'store/components/DashboardSlice';

import Popup from 'components/popup/Popup';
import Input from 'components/input/Input';
import Button from 'components/button/Button';

import './ActivationCard.scss';

const ActivationCard = () => {
  const dispatch = useAppDispatch();

  const cardNumberInStorage = localStorage.getItem(StorageItemsNames.CARD_NUMBER);

  const activationCard = useSelector((state: IStore) => state.popup.popup.activationCard);

  const [numberCard, setNumberCard] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const clearError = () => {
    setError('');
  };

  const disabledButton =
    numberCard.includes('_') || code.includes('_')
    || numberCard === '' || code  === '' || error !== '';

  const onActivationCard = () => {
    if (!loading) {
      setLoading(true);

      dispatch(activeCard({card: numberCard, pin: code}))
        .then(({payload}) => {
          setLoading(false);

          if (payload.response && payload.response.status === 500)
            setError('Ведутся технические работы, просьба повторить попытку активации позже.');
          if (payload && payload.code === 'error' && typeof payload.result === 'string')
            setError(payload.result);
        });
    }
  };

  useEffect(() => {
    if (!activationCard) {
      setCode('');
      setNumberCard('');
    }
  }, [activationCard]);

  useEffect(() => {
    if (!numberCard) {
      cardNumberInStorage && setNumberCard(cardNumberInStorage);
    }
  }, [cardNumberInStorage, activationCard]);

  return (
    <Popup id={'activationCard'} className={'activation-card'}>
      <div className={'activation-card__container'}>
        <h2 className={'activation-card__title'}>Активировать сертификат</h2>
        <p className={'activation-card__description'}>
          Введите данные с сертификата
        </p>
        <Input
          placeholder={'Номер сертификата'}
          value={numberCard}
          type={'tel'}
          onChange={clearError}
          setValue={setNumberCard}
          marginBottom={16}
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          paddingBottom={10}
        />
        <Input
          placeholder={'Код сертификата'}
          value={code}
          setValue={setCode}
          onChange={clearError}
          uppercase
          marginBottom={16}
          mask={[/./, /./, /./, /./, /./, /./, /./, /./,]}
          paddingBottom={10}
        />
        <p
          className={
            error
              ? 'activation-card__error activation-card__error_active'
              : 'activation-card__error'
          }>
          {error}
        </p>
        <Button
          width={'100%'}
          loading={loading}
          disabled={disabledButton}
          onClick={onActivationCard}
          marginBottom={34}>
          Активировать
        </Button>
      </div>
    </Popup>
  );
};

export default ActivationCard;
